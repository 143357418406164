import React, { useEffect, useState } from "react";
import "../../navbar/Navbar.css";
import TitleWithBack from "../../../containers/TitleWithBack";
import SubscriptionSummary from "../../../containers/pages/SubscriptionSummary";
import { Subscription } from "../../../api/requests/subscription";
import {
  getSubscription,
  getSubscriptionInstallations,
} from "../../../api/services/subscriptionService";
import ResponsiveTable, {
  ResponsiveTableColumnType,
} from "../../responsiveTable";
import { useHistory } from "react-router";
import { installationsListTitle } from "../title";

type InstallationsListProps = {
  subscription_id: string;
  redirectUrl: (id: string) => string;
  columns: (
    linkFunction: (value: string) => void
  ) => ResponsiveTableColumnType[];
};

const InstallationsListSubscriptionSummary: React.FC<
  InstallationsListProps
> = ({ subscription_id, redirectUrl, columns }) => {
  const history = useHistory();
  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(null);

  useEffect(() => {
    getSubscription({ subscription_id }).then((res) => {
      if (res && res.subscription) {
        setSelectedSubscription(res.subscription);
      }
    });
  }, []);

  const redirectFun: (value: string) => void = (value: string) =>
    history.push(redirectUrl(value));

  document.body.style.background = "#f5f5f5c7";

  return (
    <>
      <TitleWithBack title={installationsListTitle} />
      <SubscriptionSummary subscription={selectedSubscription} />
      <ResponsiveTable
        columns={columns(redirectFun)}
        getPageAndSortedData={getSubscriptionInstallations}
        defaultSort="name"
        responseDataName="installations"
        id={subscription_id}
      />
    </>
  );
};

export default InstallationsListSubscriptionSummary;
