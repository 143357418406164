import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Row,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import TitleWithBack from "../../../containers/TitleWithBack";
import { updateWireTitle } from "../title";
import {
  GetWire,
  UpdateSelectedWire,
} from "../../../api/services/wiresService";
import { Wire } from "../../types/timeseries/types";
import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import FormInput from "../../input/FormInput";
import FormSelect from "../../select/FormSelect";
import { wireFields } from "./input";
import moment from "moment";
import { UpdateWireRequest } from "../../../api/requests/wiresService";
import { dateType, selectInput } from "../../../utilities/utilities";
import OperationResult from "../../OperationResult";

type UpdateWireProps = {
  wireId: string;
};

const UpdateWire: React.FC<UpdateWireProps> = ({ wireId }) => {
  const [wire, setWire] = useState<Wire | null>(null);
  const [status, setStatus] = useState<"success" | "error" | null>(null);

  useEffect(() => {
    GetWire(wireId).then((res) => {
      if (res && res.wire) {
        setWire(res.wire);
      } else {
        setStatus("error");
      }
    });
  }, []);

  const forceUpdateWire = useCallback((request: UpdateWireRequest) => {
    UpdateSelectedWire(request).then((res) => {
      if (res && !res.err) {
        setStatus("success");
        form.resetFields();
      } else {
        setStatus("error");
      }
    });
  }, []);

  const ztc_rfid = (request: UpdateWireRequest) => {
    if (request.rfid === "") {
      setError("Errore di lettura del tag RFID");
    } else {
      UpdateSelectedWire(request).then((res) => {
        if (res && !res.err) {
          setStatus("success");
          form.resetFields();
        } else {
          if (res?.err?.code === 13) {
            Modal.confirm({
              title:
                "Il cartellino è già associato ad un altro cavo. Vuoi associare questo cartellino a un nuovo cavo?",
              onOk() {
                request.force = true;
                forceUpdateWire(request);
              },
              okText: "OK",
              cancelText: "Annulla",
            });
          } else {
            setStatus("error");
          }
        }
      });
    }
  };

  const [form] = Form.useForm();

  const submit = () => {
    form.validateFields().then((values) => {
      const req: UpdateWireRequest = {
        wire_id: wire?.id || "",
        rfid: "",
        name: values.name,
        description: values.description,
        model: values.model,
        wire_info: {
          production_date: values.production_date,
          diameter: values.diameter,
          mount_type: values.mount_type,
          length: values.length,
          wire_diameter: values.wire_diameter,
          wire_type: values.wire_type,
          pearl_meter: values.pearl_meter,
          max_usage: String(Number(values.max_usage) * 60 * 60),
          material: values.material,
        },
        force: false,
      };
      UpdateSelectedWire(req).then((res) => {
        if (res && !res.err) {
          setStatus("success");
        } else {
          setStatus("error");
        }
      });
    });
  };

  const [request, setRequest] = useState<any>({});
  const [reading, setReading] = useState<boolean>(false);
  const [startReading, setStartReading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const openPort = async (port: SerialPort, baudRate: number, retries = 3) => {
    if (retries > 0) {
      await port
        .open({ baudRate: baudRate })
        .then(() => {
          console.log("serial port opened successfully");
          navigator.serial.onconnect = () => {
            console.log("connected");
          };

          navigator.serial.ondisconnect = () => {
            console.log("disconnected");
          };
          return true;
        })
        .catch(async (e: any) => {
          console.log("error during port opening:", e);
          console.log("trying to close the port and reopen it again");
          await port.close();
          await openPort(port, baudRate, retries - 1);
          return false;
        });
    } else {
      console.log("retries finished. failed to open the port.");
    }
  };

  const handleReading = async () => {
    setStartReading(true);
    try {
      setError("");
      console.log("values: ", request);
      const req: UpdateWireRequest = {
        wire_id: wire?.id || "",
        rfid: request.rfid,
        name: request.name,
        description: request.description,
        model: request.model,
        wire_info: {
          production_date: new Date(request.production_date).toISOString(),
          diameter: request.diameter,
          mount_type: request.mount_type,
          length: request.length,
          wire_diameter: request.wire_diameter,
          wire_type: request.wire_type,
          pearl_meter: request.pearl_meter,
          max_usage: String(Number(request.max_usage) * 60 * 60),
          material: request.material,
        },
        force: false,
      };
      navigator.serial
        .requestPort({
          filters: [{ usbVendorId: 0x0403, usbProductId: 0x6001 }],
        })
        .then(async (port) => {
          console.log("port selected:", port);
          await openPort(port, 38400);
          console.log("port opened");
          // Read answer
          const textDecoder = new TextDecoderStream();
          const readableStreamClosed = port.readable.pipeTo(
            textDecoder.writable
          );
          const reader = textDecoder.readable.getReader();
          console.log("reader created");
          let line = "";
          // Ask bundle to device
          const enc = new TextEncoder();
          //write first command
          const writer1 = port.writable.getWriter();
          const data1 = enc.encode(`$:12010E000100000505\r\n`);
          await writer1.write(data1);
          console.log("first command sent");
          writer1.releaseLock();
          //write second command
          const writer2 = port.writable.getWriter();
          const data2 = enc.encode(`$:08012105\r\n`);
          await writer2.write(data2);
          console.log("second command sent");
          writer2.releaseLock();
          console.log("reading");

          let lineCounter = 0;
          let totalLine = "";
          while (lineCounter < 3) {
            // Continuiamo a leggere fino a 3 linee
            const { value, done } = await reader.read();

            if (done) {
              reader.releaseLock();
              break; // Se non ci sono più dati, esci dal ciclo
            }

            line += value;
            totalLine += (value || "")
              .replace(/\n/g, "\\n") // Escapes newline
              .replace(/\r/g, "\\r"); // Escapes carriage return
            // Aggiungi un controllo se hai raggiunto il termine della linea completa
            while (line.includes("\n")) {
              // Non rimuovere la parte letta, continua ad accumulare la stringa
              const index = line.indexOf("\n"); // Trova la posizione del primo '\n'
              const currentLine = line.slice(0, index + 1); // Include anche '\n' nel risultato
              lineCounter++; // Incrementa il contatore delle linee

              console.log("Line received: ", currentLine); // Mostra la linea appena letta

              // Aggiorna 'line' per conservare il resto dei dati non ancora letti
              line = line.slice(index + 1);

              if (lineCounter === 3) {
                break; // Interrompi il ciclo dopo 3 linee
              }
            }
          }

          console.log("All lines received:");
          console.log(totalLine); // Stampa tutte le linee concatenate

          if (lineCounter < 3) {
            console.log("Non tutte le righe sono state ricevute.");
          }

          console.log("LINE: ", totalLine);
          // Divide 'data' in segmenti separati da '$:'
          const dd = totalLine.split("$:");
          // Estrae il 'tag' dall'ultima parte dei dati, e rimuove la sequenza di escape finale '\r\n'
          const lastSegment = dd[dd.length - 1];
          const tag = lastSegment.split("\\r\\n")[0].slice(-1);
          // Verifica se il tag è '1'
          if (tag === "1") {
            // Prende il penultimo elemento di 'dd', rimuove '\r\n' e preleva una parte della stringa
            const rfid = dd[dd.length - 2].split("\\r\\n")[0];
            console.log("RFID:", rfid.slice(8));
            ztc_rfid({ ...req, rfid: rfid.slice(8) });
          } else {
            setError("Tag diverso da '1'. Nessun RFID valido trovato.");
            console.log("Tag diverso da '1'. Nessun RFID valido trovato.");
            setStartReading(false);
          }
          await reader.cancel();
          await readableStreamClosed.catch(() => {});
          if (port) {
            port?.close();
          }
        })
        .catch(async (e) => {
          console.log("no serial port selected:", e);
          setError(
            "Nessuno scanner trovato. Assicurarsi che sia ben collegato."
          );
          setStartReading(false);
        });
    } catch (e) {
      console.log("ERROR: ", e);
      setError("Errore durante la lettura del cavo " + String(e));
      setStartReading(false);
    }
  };

  if (!wire) {
    return null;
  }

  if (status) {
    return <OperationResult status={status} operation="update" entity="wire" />;
  }

  if (reading) {
    return (
      <>
        <TitleWithBack title={updateWireTitle} key="update_wire_title" />
        <Alert
          message={error ? "Errore" : "Attenzione"}
          description={
            error
              ? error
              : startReading
              ? "Lettura del tag RFID in corso..."
              : "Posizionare la card sullo scanner e premere OK per ottenere il tag RFID del cavo."
          }
          type={error ? "error" : "warning"}
          showIcon
        />
        <Row gutter={24} style={{ paddingTop: "24px" }}>
          <Col span={12}>
            <Button
              size="large"
              type="default"
              style={{ width: "100%" }}
              onClick={() => setReading(false)}
            >
              Annulla
            </Button>
          </Col>
          <Col span={12}>
            <Button
              size="large"
              type="primary"
              style={{ width: "100%" }}
              onClick={handleReading}
              disabled={startReading}
            >
              {error ? "Riprova" : "OK"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <TitleWithBack title={updateWireTitle} key={"update_wire_title"} />
      <div
        className="my-container my-container-responsive"
        key="update_wire_div"
      >
        <Form layout="vertical" key={1} form={form} name="wire_panel">
          <Row gutter={24} key="updateWire">
            {wireFields(wire).map((el) => {
              switch (el.type) {
                case selectInput:
                  return (
                    <Col xs={24} xl={12} key={"col_update_wire" + el.key}>
                      <FormSelect
                        key={el.key + "formselect"}
                        name={el.name}
                        keyValue={el.key}
                        placeholder={el.label}
                        options={el?.options || []}
                        rules={el.rules}
                        value={el.value || ""}
                      />
                    </Col>
                  );
                case dateType:
                  return (
                    <Col xs={24} xl={12} key={"col_update_wire" + el.key}>
                      <div className="form__group field" key={"datadiv"}>
                        <label
                          key={el.key + "datalabel"}
                          htmlFor="name"
                          className="form__label"
                        >
                          {"Data Produzione"}
                        </label>
                        <Form.Item
                          initialValue={moment(el.value)}
                          key={"dataform"}
                          name={"production_date"}
                          rules={[
                            { required: true, message: "Inserire la data!" },
                          ]}
                        >
                          <DatePicker
                            key={el.key}
                            name={el.name}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  );
                default:
                  return (
                    <Col xs={24} xl={12} key={"col_update_wire" + el.key}>
                      <FormInput
                        key={el.key + "forminput"}
                        name={el.name}
                        keyValue={el.key}
                        placeholder={el.label}
                        type={el.type ?? "text"}
                        rules={el.rules}
                        value={el.value || ""}
                      />
                    </Col>
                  );
              }
            })}
          </Row>
          <div className="btn-container" key={"update_wire_btn"}>
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnConfirm={() => {
                form.resetFields();
              }}
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={submit}
            />
          </div>
          <Divider />
          <FormInput
            key="rifdforminput"
            name="rfid"
            keyValue="rfid"
            placeholder="RFID"
            type="text"
            value={wire.rfid}
            disabled
          />
          <div className="btn-container" key={"update_rfid_btn"}>
            <ButtonItem
              buttonType="primary"
              label="Modifica RFID"
              buttonOnClick={() => {
                form.validateFields().then((values) => {
                  setRequest({ ...values });
                  setReading(true);
                });
              }}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default UpdateWire;
