import React from "react";
import { updateInstallationTableColumns } from "./inputs";
import { GetAllInstallations } from "../../../api/services/installationsService";
import CustomTitle from "../../CustomTitle";
import ResponsiveTable from "../../responsiveTable";
import { useHistory } from "react-router";
import { updateInstallationUrl } from "../costants";
import { selectInstallationTitle } from "../title";

const InstallationsListPage: React.FC = () => {
  document.body.style.background = "#f5f5f5c7";

  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) =>
    history.push(updateInstallationUrl(value));

  return (
    <>
      <CustomTitle title={selectInstallationTitle} />
      <ResponsiveTable
        columns={updateInstallationTableColumns(linkFunction)}
        getPageAndSortedData={GetAllInstallations}
        defaultSort="company"
        responseDataName="installations"
      />
    </>
  );
};

export default InstallationsListPage;
