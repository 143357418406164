import { GetDataRequest, GetExportInfoRequest } from "./requests/timeSeries";

export const isDev =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";

export const BackendUrl = window._env_.API_ENDPOINT!;
export const GrafanaUrl = window._env_.GRAFANA_ENDPOINT!;
export const zdmUrl =
  BackendUrl === "https://api.app.megadiamant.zerynth.com"
    ? "https://app.megadiamant.zerynth.com/proxy"
    : `https://apptest.megadiamant.zerynth.com/proxy`;

export const LoginUrl = `${BackendUrl}/auth/login`;
export const LogoutUrl = `${BackendUrl}/auth/logout`;
export const SetNewPasswordUrl = `${BackendUrl}/auth/recover/end`;
export const ResetPasswordUrl = `${BackendUrl}/auth/recover`;
export const GetProfileUrl = `${BackendUrl}/auth/profile`;

// TimeSeries service
export const TimeSeriesUrl = `${BackendUrl}/timeseries`;
export const getLastWiresUrl = (device_id: string) =>
  `${TimeSeriesUrl}/wires/${device_id}?page=0&rows=${rowsPerTablePage}&sort=-usage_time`;

// Installations Service
export const InstallationsServiceUrl = `${BackendUrl}/installations`;

// Users Service
export const UsersServiceUrl = `${BackendUrl}/users`;

// Subscription Service
export const SubscriptionsUrl = `${BackendUrl}/subscriptions`;
export const GetSubscriptionUrl = (subscription_id: string) => {
  return `${BackendUrl}/subscriptions/${subscription_id}`;
};
export const UpdateSubscriptionInfoUrl = (subscription_id: string) => {
  return `${BackendUrl}/subscriptions/${subscription_id}/info`;
};
export const UpdateSubscriptionStatusUrl = (subscription_id: string) => {
  return `${BackendUrl}/subscriptions/${subscription_id}/status`;
};
export const UpdateSubscriptionExpDateUrl = (subscription_id: string) => {
  return `${BackendUrl}/subscriptions/${subscription_id}/exp`;
};
export const GetSubscriptionInstallationsUrl = (
  subscription_id: string,
  page: string,
  sort: string,
  search: string
) => {
  return `${BackendUrl}/subscriptions/${subscription_id}/installations?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`;
};
export const changeStatusUrl = (subscription_id: string) => {
  return `${BackendUrl}/subscriptions/${subscription_id}/changestatus`;
};

export const GetDataUrl = (req: GetDataRequest) => {
  const baseUrl = new URL(
    `${TimeSeriesUrl}/data/${req?.device_id}/${req?.tag}`
  );
  baseUrl.searchParams.append("start", req?.start ?? "");
  baseUrl.searchParams.append("end", req?.end ?? "");
  baseUrl.searchParams.append("bucket", req?.bucket ?? "");
  req?.aggregation?.map((value) => {
    baseUrl.searchParams.append("aggregation", value ?? "");
  });
  baseUrl.searchParams.append("sort", req?.sort ?? "");
  baseUrl.searchParams.append("bucket_gap", "yes");
  return baseUrl.toString();
};

export const GetExportInfoUrl = (req: GetExportInfoRequest) => {
  const baseUrl = new URL(`${TimeSeriesUrl}/data/exports`);
  baseUrl.searchParams.append("start", req.start);
  baseUrl.searchParams.append("end", req.end);
  baseUrl.searchParams.append("entity_id", req.entity_id);
  baseUrl.searchParams.append("tag", req.tag);
  return baseUrl.toString();
};

//Wire service
export const WireUrl = `${BackendUrl}/wires`;

//Configurator service
export const createNewDeviceUrl = (workspaceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices`;
export const getIdentityFromDCNUrl = (dcn: string) =>
  `${zdmUrl}/identities/${dcn}/devices`;
export const claimDeviceUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/identities`;
export const activeSimUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/sims`;
export const mklfsUrl = `${zdmUrl}/mklfs/`; // Non rimuovere lo / finale perché senno smette di funzionare
export const initUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/init`;
export const uploadFilesUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/upload`;
export const commitUrl = (
  workspaceId: string,
  deviceId: string,
  schedule: boolean
) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/commit?auto_schedule=${
    schedule ? "true" : "false"
  }`;
export const getFirmwareVersionsUrl = (
  workspaceId: string,
  firmwareId: string
) => `${zdmUrl}/workspaces/${workspaceId}/firmwares/${firmwareId}/versions`;
export const getCloudDeviceUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}`;
export const getZfsUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/files?zone=R`;
export const getZfsFileUrl = (
  workspaceId: string,
  deviceId: string,
  zfsId: string
) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/download/${zfsId}`;
export const checkZfsStatusUrl = (deviceId: string) =>
  `${zdmUrl}/devices/${deviceId}/jobs/zfs`;

//TABLE
export const rowsPerTablePage: number = 50;

//VALIDATION
export function isValidIPv4(str: string) {
  return /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)$/g.test(
    str
  );
}

export function isValidSSID(str: string) {
  return /^[^!#;+\]/"\t][^+\]/"\t]{0,30}[^ +\]/"\t]$|^[^ !#;+\]/"\t]$[ \t]+$/g.test(
    str
  );
}

export function isValidWPA2(str: string) {
  return /^[\u0020-\u007e]{8,63}$/g.test(str);
}

//JSON
export const prepareNetJson = (apn: string, customWifi: any) => {
  return {
    IFCS: [
      {
        ifc_name: "wifi",
        ifc_params: {
          ssid: "assistenza",
          pwd: "12345678",
        },
      },
      { ...customWifi },
      {
        ifc_name: "cellular",
        ifc_params: { apn: apn },
      },
    ],
  };
};

export const prepareParamsJsonPinzaAmperometrica = (
  ta1_wthresh: number,
  ta2_wthresh: number,
  ta3_wthresh: number,
  spd_en: "enabled" | "disabled",
  drag_en: "enabled" | "disabled",
  model: 0 | 1
) => {
  return {
    TA1: {
      sensor: "IN1",
      clamp_type: "TT",
      w_thresh: ta1_wthresh,
      three_phase: true,
      rolling_mode: "avg",
      buff_len: 1,
    },
    TA2: {
      sensor: "IN3",
      clamp_type: "TT",
      w_thresh: ta2_wthresh,
      three_phase: true,
      rolling_mode: "avg",
      buff_len: 10,
    },
    TA3: {
      sensor: "IN4",
      clamp_type: "TT",
      w_thresh: ta3_wthresh,
      three_phase: true,
      rolling_mode: "avg",
      buff_len: 10,
    },
    SPD_EN: spd_en,
    DRAG_EN: drag_en,
    MODEL: model,
  };
};

export const prepareParamsJsonLochmas = (
  ta1_wthresh: number,
  ta1_three_phase: boolean,
  spd_en: "enabled" | "disabled",
  drag_en: "enabled" | "disabled",
  am_en: "enabled" | "disabled"
) => {
  return {
    TA1: {
      sensor: "IN1",
      clamp_type: "TT",
      w_thresh: ta1_wthresh,
      three_phase: ta1_three_phase,
      rolling_mode: "avg",
      buff_len: 1,
    },
    SPD_EN: spd_en,
    DRAG_EN: drag_en,
    AM_EN: am_en,
    MODEL: 0,
  };
};

export const prepareSensorsJsonPinzaAmperometrica = {
  IN1: {
    set: {
      type: "current_ac",
      exp: null,
      input_number: 1,
      PGA: 2,
      SPS: 3000,
    },
    conversion: {
      type: "power",
      args: {
        n_coil: 1,
        n_samples: 80,
        ratio: 3000,
        voltage: 230,
        offset: 0,
        vref: 2.048,
      },
    },
  },
  IN3: {
    set: {
      type: "current_ac",
      exp: null,
      input_number: 3,
      PGA: 2,
      SPS: 3000,
    },
    conversion: {
      type: "power",
      args: {
        n_coil: 1,
        n_samples: 80,
        ratio: 3000,
        voltage: 230,
        offset: 0,
        vref: 2.048,
      },
    },
  },
  IN4: {
    set: {
      type: "current_ac",
      exp: null,
      input_number: 4,
      PGA: 2,
      SPS: 3000,
    },
    conversion: {
      type: "power",
      args: {
        n_coil: 1,
        n_samples: 80,
        ratio: 3000,
        voltage: 230,
        offset: 0,
        vref: 2.048,
      },
    },
  },
};

export const prepareSensorsJsonLochmas = (
  speed_ymin: number,
  speed_ymax: number
) => {
  return {
    IN1: {
      set: {
        type: "current_ac",
        exp: null,
        input_number: 1,
        PGA: 2,
        SPS: 3000,
      },
      conversion: {
        type: "power",
        args: {
          n_coil: 1,
          n_samples: 80,
          ratio: 3000,
          voltage: 230,
          offset: 0,
          vref: 2.048,
        },
      },
    },
    AUTO_MAN: {
      set: {
        type: "voltage",
        exp: null,
        input_number: 2,
        PGA: 2,
        SPS: 3300,
      },
      conversion: {
        type: "linear",
        args: {
          x_min: 0,
          x_max: 10,
          y_min: 0,
          y_max: 10,
          offset: 0,
          under_x: 0.0,
          over_x: 10.0,
        },
      },
    },
    SPEED: {
      set: {
        type: "voltage",
        exp: null,
        input_number: 3,
        PGA: 2,
        SPS: 3300,
      },
      conversion: {
        type: "linear",
        args: {
          x_min: 0,
          x_max: 10,
          y_min: speed_ymin || 0,
          y_max: speed_ymax || 100,
          offset: 0,
          under_x: 0.0,
          over_x: 1000.0,
        },
      },
    },
    DRAG: {
      set: {
        type: "voltage",
        exp: null,
        input_number: 4,
        PGA: 2,
        SPS: 3300,
      },
      conversion: {
        type: "linear",
        args: {
          x_min: 0,
          x_max: 10,
          y_min: 0,
          y_max: 100,
          offset: 0,
          under_x: 0.0,
          over_x: 100.0,
        },
      },
    },
  };
};

export const prepareConfigJson = {
  params: {
    wtd: 60000,
    acq_freq: 1000,
    fast_freq: 0,
    pub_freq: 1000,
    agent_timeout: 1500,
    store_period: 300,
    info_period: 1800,
    exc_period: 1800,
    dn_chunk_size: 32768,
    dn_wtd: 120000,
    offline_thr: 20,
    resolve_thr: 5,
    tlog_rsize: 720,
    tlog_bsize: 128,
    tlog_thr: 2,
  },
  exps: [],
};
