import { GetWireReports, GetWires } from "../../../api/services/wiresService";
import React, { useEffect, useState } from "react";
import { Badge, Card, Descriptions, Select } from "antd";
import CustomTitle from "../../CustomTitle";
import { ReportTitle } from "../title";
import moment from "moment";
import ResponsiveTable from "../../responsiveTable";
import { downloadFile, reportListColumns } from "./columns";
import { IconButton } from "@material-ui/core";
import { CloudDownloadTwoTone } from "@material-ui/icons";
import { BackendUrl } from "../../../api/constants";

const { Option } = Select;

const Report: React.FC = () => {
  document.body.style.background = "#f5f5f5c7";

  const [selectedWire, setSelectedWire] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [wires, setWires] = useState<any[]>([]);
  useEffect(() => {
    GetWires("-1", "", "").then((res) => {
      if (res && res.wires) {
        setWires(res.wires);
      }
      setLoading(false);
    });
  }, []);

  const [reports, setReports] = useState<any[]>([
    {
      id: "rep9245tlw491z0",
      wire_id: "wire6a5lr3id8xdt",
      name: "25-06-2024.pdf",
      status: "done",
      start: "2024-05-01T00:00:00Z",
      end: "2024-05-31T23:59:59Z",
      report_type: "periodic",
      template_id: "VYGw772hf-",
      created_at: "2024-06-25T04:55:00.011908Z",
    },
  ]);
  useEffect(() => {
    if (selectedWire) {
      GetWireReports(selectedWire.id, "0", "-created_at", "").then((res) => {
        if (res && res.reports) {
          setReports(res.reports);
        }
      });
    }
  }, [selectedWire]);

  if (loading) {
    return null;
  }

  return (
    <>
      <CustomTitle title={ReportTitle} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p>Seleziona un filo</p>
      </div>
      <Select
        style={{ width: "100%", marginBottom: "32px" }}
        size="large"
        placeholder="Seleziona un filo"
        optionFilterProp="children"
        onChange={(wire: string) => {
          setSelectedWire(wires.filter((el) => el.id === wire)[0]);
        }}
      >
        {wires.map((el, index) => (
          <Option value={el.id} key={index}>
            {el.name}
          </Option>
        ))}
      </Select>
      {selectedWire && (
        <Descriptions
          column={2}
          bordered
          style={{ width: "100%", marginBottom: "32px" }}
        >
          <Descriptions.Item label="Nome">
            {selectedWire?.name || "---"}
          </Descriptions.Item>
          <Descriptions.Item label="Modello">
            {selectedWire?.model || "---"}
          </Descriptions.Item>
          <Descriptions.Item label="Lunghezza">
            {selectedWire?.wire_info?.length || "---"} m
          </Descriptions.Item>
          <Descriptions.Item label="Data di produzione">
            {moment(selectedWire?.wire_info?.production_date).format(
              "DD/MM/YYYY HH:mm"
            ) || "---"}
          </Descriptions.Item>
        </Descriptions>
      )}
      {selectedWire && (
        <div
          style={{
            width: "100%",
            marginBottom: "32px",
            paddingRight: "20%",
            paddingLeft: "20%",
          }}
        >
          <Badge.Ribbon
            text="Report fine vita"
            style={{ backgroundColor: "#009fc6" }}
          >
            <Card size="small">
              {reports.length > 0 &&
              reports.filter((el) => el.report_type === "lifetime").length >
                0 ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={async () => {
                    const tmpWire = reports.filter(
                      (el) => el.report_type === "lifetime"
                    )[0];
                    downloadFile(
                      `${BackendUrl}/reportsmanager/wires/${tmpWire.wire_id}/${tmpWire.id}/download`
                    );
                  }}
                >
                  <CloudDownloadTwoTone />
                </IconButton>
              ) : (
                "Cavo in uso, report non disponibile"
              )}
            </Card>
          </Badge.Ribbon>
        </div>
      )}
      {selectedWire && (
        <ResponsiveTable
          columns={reportListColumns}
          data={reports.filter((el) => el.report_type === "periodic")}
        />
      )}
    </>
  );
};

export default Report;
