import "./resetPassword.css";
import { Result } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../../../logo/logo-wires02.png";

type ResultResetPasswordProps = {};

const ResultResetPassword: React.FC<ResultResetPasswordProps> = () => {
  document.body.style.background =
    "radial-gradient(circle, rgba(250,238,12,1) 13%, rgba(245,202,38,1) 32%, rgba(8,159,199,1) 100%)";
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div className={width <= 768 ? "recover-box-responsive" : "recover-box"}>
      <div className="image-container">
        <img src={logo} className="image" alt="logo" />
      </div>
      <Result
        status="success"
        title="Email inviata correttamente"
        subTitle="Controlla la tua email per proseguire con il recupero della password."
      />
    </div>
  );
};

export default ResultResetPassword;
