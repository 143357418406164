import { Button, Steps } from "antd";
import React, { useEffect, useState } from "react";
import TitleWithBack from "../../../containers/TitleWithBack";
import { connectionTitle } from "../title";
import { ESPLoader } from "../../../misc/esp-web-flasher";
import { useHistory } from "react-router";
import {
  isDev,
  prepareConfigJson,
  prepareNetJson,
  prepareParamsJsonLochmas,
  prepareParamsJsonPinzaAmperometrica,
  prepareSensorsJsonLochmas,
  prepareSensorsJsonPinzaAmperometrica,
  zdmUrl,
} from "../../../api/constants";
import {
  ActiveSim,
  claimDevice,
  commit,
  getFirmwareVersions,
  getIdentityFromDCN,
  init,
  mklfs,
  uploadFiles,
} from "../../../api/services/configuratorService";
import {
  binaryFetch,
  firmwareIdPinzaAmperometrica,
  firmwareIdStandard,
  workspaceId,
} from "../../../api/services/httpRequests";
import { installationPageUrl } from "../costants";
import { getInstallation } from "../../../api/services/installationsService";

const { Step } = Steps;

type UploadFwProps = {
  installation_id: string;
};

const UploadFw: React.FC<UploadFwProps> = ({ installation_id }) => {
  const history = useHistory();

  const partitions = {
    bootloader: new ArrayBuffer(0), //os/bootloader.bin
    partitions: new ArrayBuffer(0), //os/partitions.bin
    zerynth: new ArrayBuffer(0), //os/zerynth.bin
    firmware: new ArrayBuffer(0), //firmware/firmware.bin
    otalog: new ArrayBuffer(0), //os/otalog.bin
    fs: new ArrayBuffer(0), //resources/fs.bin
  };

  const [activeStep, setActiveStep] = useState<number>(0);
  const [devicePort, setDevicePort] = useState<SerialPort | null>(null);
  const [processError, setProcessError] = useState<string>("");
  const [flashPercentage, setFlashPercentage] = useState<number>(0);
  const [firmwareType, setFirmwareType] = useState<
    "loading" | "standard" | "pinza_amperometrica"
  >("loading");
  useEffect(() => {
    getInstallation({ id: installation_id }).then((res) => {
      if (res && res.installation) {
        setFirmwareType(res.installation.firmware_type);
      }
    });
  }, []);

  //STEP 1: checkingUsbConnection
  const handleDeviceSelect = () => {
    navigator.serial
      .requestPort({ filters: [{ usbVendorId: 0x10c4, usbProductId: 0xea60 }] })
      .then((port) => {
        console.log("port selected:", port);
        setDevicePort(port);
        setActiveStep((prev) => prev + 1);
        handleFirmwarePreferences(port);
      })
      .catch((e) => {
        console.log("no serial port selected:", e);
      });
  };

  //STEP 2: updatingTheFirmware
  const openPort = async (port: SerialPort, baudRate: number, retries = 3) => {
    if (retries > 0) {
      await port
        .open({ baudRate: baudRate })
        .then(() => {
          console.log("serial port opened successfully");
          navigator.serial.onconnect = () => {
            console.log("connected");
          };

          navigator.serial.ondisconnect = () => {
            console.log("disconnected");
            setActiveStep(0);
            setDevicePort(null);
            console.log("disconnected");
          };
          return true;
        })
        .catch(async (e: any) => {
          console.log("error during port opening:", e);
          console.log("trying to close the port and reopen it again");
          await port.close();
          await openPort(port, baudRate, retries - 1);
          return false;
        });
    } else {
      setProcessError(`Tentativi terminati: impossibile aprire la porta USB`);
      console.log("retries finished. failed to open the port.");
    }
  };

  const getPartitions = async () => {
    try {
      const test = JSON.parse(
        localStorage.getItem("zerynth_megadiamant_configuration") || "{}"
      );
      console.log("test", test);
      const fwType = test?.firmware_type || "standard";
      const vers = (await getFirmwareVersions(
        fwType === "standard"
          ? firmwareIdStandard
          : firmwareIdPinzaAmperometrica
      )) as any;
      const version = vers.versions[0].version || "v0.0.0";
      for (const k of Object.keys(partitions)) {
        console.log("Starting fetching firmware", k);
        if (k != "fs") {
          // @ts-ignore
          const fileIndex: number = vers.versions[0].sources.find((s: any) =>
            s.name.includes(k)
          ).src_id;
          console.log("fileIndex", fileIndex);
          // @ts-ignore
          partitions[k] = (await binaryFetch(
            "GET",
            `${zdmUrl}/workspaces/${workspaceId}/firmwares/${
              fwType === "standard"
                ? firmwareIdStandard
                : firmwareIdPinzaAmperometrica
            }/versions/${version}/download?source=${fileIndex}`
          )) as ArrayBuffer;
        }
      }

      console.log("before mklfsService");
      console.log("test", test);
      const resp = await mklfs({
        files: {
          "zfs/net.json": JSON.stringify(
            prepareNetJson(test?.apn || "iot.secure", {
              ifc_name: "wifi",
              ifc_params: {
                ssid: test?.sid || "assistenza",
                pwd: test?.password || "12345678",
              },
            })
          ),
          "zfs/params.json": JSON.stringify(
            fwType === "pinza_amperometrica"
              ? prepareParamsJsonPinzaAmperometrica(
                  test?.ta1_wthresh * 1000 || 90000, //da kW a W
                  test?.ta2_wthresh * 1000 || 90000, //da kW a W
                  test?.ta3_wthresh * 1000 || 90000, //da kW a W
                  test?.spd_en || "enabled",
                  test?.drag_en || "enabled",
                  1
                )
              : prepareParamsJsonLochmas(
                  test?.ta_threshold * 1000 || 90000, //da kW a W
                  test?.ta_multi === "enabled" ? true : false,
                  test?.spd_en || "enabled",
                  test?.drag_en || "enabled",
                  test?.am_en || "enabled"
                )
          ),
          "zfs/sensors.json": JSON.stringify(
            fwType === "pinza_amperometrica"
              ? prepareSensorsJsonPinzaAmperometrica
              : prepareSensorsJsonLochmas(
                  Number(test?.spd_en_min),
                  Number(test?.spd_en_max)
                )
          ),
          "zfs/config.json": JSON.stringify(prepareConfigJson),
        },
      });
      if (!resp) {
        setProcessError(`Fallito servizio MKLFS`);
        console.log("failed to mklfs");
        return "";
      } else {
        partitions["fs"] = resp as ArrayBuffer;
        console.log("after mklfsService");
        //zfs
        const netFile = {
          file: new File(
            [
              new TextEncoder().encode(
                JSON.stringify(
                  prepareNetJson(test?.apn || "iot.secure", {
                    ifc_name: "wifi",
                    ifc_params: {
                      ssid: test?.sid || "assistenza",
                      pwd: test?.password || "12345678",
                    },
                  })
                )
              ),
            ] as BlobPart[],
            "net.json"
          ),
        };
        const paramsFile = {
          file: new File(
            [
              new TextEncoder().encode(
                JSON.stringify(
                  fwType === "pinza_amperometrica"
                    ? prepareParamsJsonPinzaAmperometrica(
                        test?.ta1_wthresh * 1000 || 90000, //da kW a W
                        test?.ta2_wthresh * 1000 || 90000, //da kW a W
                        test?.ta3_wthresh * 1000 || 90000, //da kW a W
                        test?.spd_en || "enabled",
                        test?.drag_en || "enabled",
                        1
                      )
                    : prepareParamsJsonLochmas(
                        test?.ta_threshold * 1000 || 90000, //da kW a W
                        test?.ta_multi === "enabled" ? true : false,
                        test?.spd_en || "enabled",
                        test?.drag_en || "enabled",
                        test?.am_en || "enabled"
                      )
                )
              ),
            ] as BlobPart[],
            "params.json"
          ),
        };
        const sensorsFile = {
          file: new File(
            [
              new TextEncoder().encode(
                JSON.stringify(
                  fwType === "pinza_amperometrica"
                    ? prepareSensorsJsonPinzaAmperometrica
                    : prepareSensorsJsonLochmas(
                        Number(test?.spd_en_min),
                        Number(test?.spd_en_max)
                      )
                )
              ),
            ] as BlobPart[],
            "sensors.json"
          ),
        };
        const configFile = {
          file: new File(
            [
              new TextEncoder().encode(JSON.stringify(prepareConfigJson)),
            ] as BlobPart[],
            "config.json"
          ),
        };
        init(installation_id).then(
          async () =>
            await uploadFiles(installation_id, [
              netFile,
              paramsFile,
              sensorsFile,
              configFile,
            ]).then(async (res: any) => {
              if (res && res.files) {
                await commit(installation_id, true);
              }
            })
        );
        return installation_id;
      }
    } catch (e) {
      setProcessError(String(e));
      console.log(e);
      devicePort?.close();
    }
  };

  //STEP 3: uploadTheFirmware
  const flash = async (devicePort: any) => {
    console.log("before get partitions");
    const devId = await getPartitions();
    console.log("after get partitions");
    try {
      console.log("DP", devicePort);
      if (devicePort) {
        setActiveStep((prev) => prev + 1);
        await openPort(devicePort, 115200);

        const loader = new ESPLoader(devicePort, {
          log: (...args) => console.log(...args),
          debug: (...args) => console.log(...args),
          error: (...args) => console.log(...args),
        });
        console.log("loader initializer", loader);
        try {
          await loader.initialize();
        } catch (error) {
          console.log("loader initialize error", error);
        }
        console.log("loader runstub");
        const espStub = await loader.runStub();
        console.log("setbaudrate");
        await espStub.setBaudrate(921600);
        console.log("PARTITIONS", partitions);
        const ps = [
          {
            name: "bootloader",
            data: partitions.bootloader,
            offset: 0x1000,
          },
          {
            name: "partitions",
            data: partitions.partitions,
            offset: 0x9000,
          },
          {
            name: "otalog",
            data: partitions.otalog,
            offset: 0x910000,
          },
          {
            name: "zerynth",
            data: partitions.zerynth,
            offset: 0x10000,
          },
          {
            name: "firmware",
            data: partitions.firmware,
            offset: 0x210000,
          },
          {
            name: "fs",
            data: partitions.fs,
            offset: 0x920000,
          },
        ];

        for (const p of ps) {
          const i = ps.indexOf(p);
          await espStub.flashData(
            p.data,
            (bytesWritten, totalBytes) => {
              setFlashPercentage(
                i * (100 / ps.length) +
                  (100 / ps.length) * (bytesWritten / totalBytes)
              );
            },
            p.offset
          );
        }

        console.log("stub disconnect");
        await espStub.hardReset();
        await espStub.disconnect();
        await devicePort.close();
        console.log("device flashed successfully");
        return devId;
      } else {
        setProcessError(`Nessun dispositivo collegato alla porta USB`);
        console.log("NO DEVICE PORT");
      }
    } catch (e) {
      if (devicePort) {
        devicePort?.close();
      }
      setProcessError(
        "Impossibile eseguire l'upload del firmware: " + String(e)
      );
      console.log("failed to flash firmware", e);
      return "";
    }
    return devId;
  };

  const handleFirmwarePreferences = (port: any) => {
    flash(port).then(async (res) => {
      if (res) {
        console.log("device flashed successfully");
        setActiveStep((prev) => prev + 1);
        await handleClaimingDevice(port, res);
      } else {
        setProcessError(`Impossibile eseguire l'upload del firmware`);
      }
    });
  };

  //STEP 4: claimingDevice
  const handleClaimingDevice = async (
    devicePort: SerialPort,
    devId: string
  ) => {
    console.log("Starting claim procedure. Device Port: ", devicePort);
    await openPort(devicePort, 115200);
    console.log("port opened");
    // Read answer
    const textDecoder = new TextDecoderStream();
    const readableStreamClosed = devicePort.readable.pipeTo(
      textDecoder.writable
    );
    const reader = textDecoder.readable.getReader();
    console.log("reader created");
    let line = "";
    // Ask bundle to device
    const enc = new TextEncoder();
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (isDev) {
        break;
      } else {
        console.log("reading");
        const { value, done } = await reader.read();
        if (done) {
          reader.releaseLock();
          break;
        }
        const nonce = Math.floor(new Date().getTime() / 1000);
        line += value;
        if (value && value.includes("\n")) {
          console.log(line);
          if (line.includes("pong")) {
            if (devicePort) {
              const writer = devicePort.writable.getWriter();
              const data = enc.encode(`bundle ${nonce}\n`);
              await writer.write(data);
              writer.releaseLock();
            }
          } else if (line.includes(":")) {
            const bundle = line.replaceAll("\n", "").replaceAll("#", "");
            const parts = bundle.split(":");
            try {
              const b1 = window.atob(parts[0]);
              const b1o = JSON.parse(b1);
              // If the first part of the decoded bundle contains the same nonce => this is the bundle
              // Workaround: sometimes the nonce received from the firmware is x-1
              if (b1o.Nonce === `${nonce}` || b1o.Nonce === `${nonce - 1}`) {
                try {
                  console.log("bundle received ", devId);
                  const res = (await getIdentityFromDCN(b1o.DCN)) as any;
                  if (res && res.identity) {
                    if (res.identity.device_id !== devId) {
                      // Device already claimed by another device
                      setProcessError(
                        `Il dispositivo fisico è già stato associato al device ${res.identity.device_id}. Dovresti disassociare il device prima di andare avanti.`
                      );
                      console.log("device already claimed (another device id)");
                    } else {
                      console.log("device already claimed (this device id)");
                      setActiveStep((prev) => prev + 1);
                      createDashboard();
                    }
                    const loader = new ESPLoader(devicePort, {
                      log: (...args) => console.log(...args),
                      debug: (...args) => console.log(...args),
                      error: (...args) => console.log(...args),
                    });
                    const espStub = await loader.runStub();
                    console.log("setbaudrate");
                    await espStub.setBaudrate(460800);
                    console.log("hardreset after claim");
                    await espStub.hardReset();
                    await espStub.disconnect();
                    await reader.cancel();
                    await readableStreamClosed.catch(() => {});

                    const writer = devicePort.writable.getWriter();
                    const data = enc.encode(`exit\n`);
                    await writer.write(data);
                    writer.releaseLock();
                    break;
                  } else {
                    const res = (await claimDevice(
                      devId,
                      //b1o.DCN,
                      bundle
                    )) as any;
                    if (res && res.identity) {
                      console.log("device claimed successfully");
                      await reader.cancel();
                      await readableStreamClosed.catch(() => {});
                      const writer = devicePort.writable.getWriter();
                      const data = enc.encode(`exit\n`);
                      await writer.write(data);
                      writer.releaseLock();
                      const test = JSON.parse(
                        localStorage.getItem(
                          "zerynth_megadiamant_configuration"
                        ) || "{}"
                      );
                      console.log("test", test);
                      if (test && test.sim) {
                        const resSim: any = await ActiveSim(devId);
                        if (resSim && resSim.sim) {
                          console.log("Sim claimed successfully");
                          setActiveStep((prev) => prev + 1);
                          createDashboard();
                          break;
                        } else {
                          setProcessError(
                            "Attivazione SIM fallita " + resSim?.err?.message
                          );
                          break;
                        }
                      } else {
                        console.log(
                          "Sim not claimed beacasue not present in configuration"
                        );
                        setActiveStep((prev) => prev + 1);
                        createDashboard();
                        break;
                      }
                    } else {
                      if (
                        res.message &&
                        (res.message.includes("could be counterfeited") ||
                          res.message.includes("is unknown"))
                      ) {
                        setProcessError("Error: " + res.message);
                        await reader.cancel();
                        await readableStreamClosed.catch(() => {});
                        const loader = new ESPLoader(devicePort, {
                          log: (...args: any) => console.log(...args),
                          debug: (...args: any) => console.log(...args),
                          error: (...args: any) => console.log(...args),
                        });
                        const espStub = await loader.runStub();
                        console.log("setbaudrate");
                        await espStub.setBaudrate(921600);
                        console.log("hardreset after claim");
                        await espStub.hardReset();
                        await espStub.disconnect();
                        break;
                      } else {
                        // TODO: handle other error responses
                      }
                    }
                  }
                } catch (e) {
                  console.log("catch", e);
                  break;
                } finally {
                  await reader.cancel();
                  await readableStreamClosed.catch(() => {});
                  if (devicePort) {
                    devicePort?.close();
                  }
                }
              }
            } catch {
              console.log("failed to parse bundle");
            }
          } else if (line.includes("Inactivity timeout")) {
            console.log("timeout");
          } else if (line.includes("#")) {
            setTimeout(async () => {
              if (devicePort) {
                const writer = devicePort.writable.getWriter();
                const data = enc.encode(`ping\n`);
                await writer.write(data);
                writer.releaseLock();
              }
            }, 2000);
          }
          line = "";
        }
      }
    }
    if (isDev) {
      console.log("TESTLOCAL - device claimed successfully");
      setActiveStep((prev) => prev + 1);
      createDashboard();
    }
  };

  //STEP 5: creatingDashboard
  const createDashboard = async () => {
    setTimeout(() => {
      setActiveStep((prev) => prev + 1);
    }, 3000);
  };

  useEffect(() => {
    handleDeviceSelect();
  }, []);

  if (firmwareType === "loading") {
    return <div>Caricamento...</div>;
  }
  console.log("FIRMWARE TYPE", firmwareType);
  return (
    <>
      <TitleWithBack title={connectionTitle} key="add_connection" />
      <div className="my-container">
        <Steps current={activeStep} direction="vertical">
          <Step
            title="Controllo della connessione USB"
            description={
              processError && activeStep === 0 ? (
                processError
              ) : activeStep === 0 ? (
                <Button
                  type="primary"
                  size="small"
                  onClick={handleDeviceSelect}
                >
                  Seleziona dispositivo
                </Button>
              ) : null
            }
            status={
              activeStep === 0 && processError
                ? "error"
                : activeStep === 0
                ? "process"
                : activeStep > 0
                ? "finish"
                : "wait"
            }
          />
          <Step
            title="Configurazione impostazioni del firmware"
            status={
              activeStep === 1 && processError
                ? "error"
                : activeStep === 1
                ? "process"
                : activeStep > 1
                ? "finish"
                : "wait"
            }
            description={
              processError && activeStep === 1
                ? processError
                : activeStep === 1
                ? "In corso"
                : null
            }
          />
          <Step
            title="Aggiornamento del firmware"
            description={
              processError && activeStep === 2 ? (
                processError
              ) : activeStep === 2 ? (
                <div>In corso: {flashPercentage.toFixed(2)}%</div>
              ) : null
            }
            status={
              activeStep === 2 && processError
                ? "error"
                : activeStep === 2
                ? "process"
                : activeStep > 2
                ? "finish"
                : "wait"
            }
          />
          <Step
            title="Claim del device"
            status={
              activeStep === 3 && processError
                ? "error"
                : activeStep === 3
                ? "process"
                : activeStep > 3
                ? "finish"
                : "wait"
            }
            description={
              processError && activeStep === 3
                ? processError
                : activeStep === 3
                ? "In corso"
                : null
            }
          />
          <Step
            title={
              activeStep === 5
                ? "Configurazione terminata con successo"
                : "Creazione dashboard"
            }
            description={
              activeStep === 4 ? (
                "In corso"
              ) : activeStep === 5 ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ paddingBottom: "16px" }}>
                    Il dispositivo è ora pronto per essere installato seguendo
                    lo schema elettrico. Una volta completata l'installazione,
                    sarà possibile accedere ai dati del dispositivo tramite la
                    sezione <b>Dati Real Time</b>.
                  </div>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href =
                        firmwareType === "pinza_amperometrica"
                          ? "/SE_pinze.pdf"
                          : "/SE_lochtmans.pdf";
                      link.download = `schema_elettrico_fw_${firmwareType}.pdf`;
                      link.click();
                      history.push(installationPageUrl(installation_id));
                    }}
                  >
                    Download schema elettrico
                  </Button>
                </div>
              ) : null
            }
          />
        </Steps>
      </div>
    </>
  );
};

export default UploadFw;
