import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Form, message } from "antd";
import { Installation } from "../../../api/requests/installationsService";
import ButtonConfItem from "../../ButtonConfItem";
import ButtonItem from "../../ButtonItem";
import TitleWithBack from "../../../containers/TitleWithBack";
import InstallationSummary from "../../../containers/installationSummary/InstallationSummary";
import { getInstallation } from "../../../api/services/installationsService";
import moment from "moment";
import { dataHistoryTitle } from "../title";
import { GetExportInfoRequest } from "../../../api/requests/timeSeries";
import { getExportInfo } from "../../../api/services/timeSeries";

type DataHistoryPanelProps = {
  installation_id: string;
};

const DataHistoryPanel: React.FC<DataHistoryPanelProps> = ({
  installation_id,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    getInstallation({ id: installation_id }).then((res) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      }
      setLoading(false);
    });
  }, []);

  const downloadFile = useCallback((url: string) => {
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = url;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }, []);

  const dateFormatter = (date: Date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const first = new Date(values.date[0]);
      const second = new Date(values.date[1]);
      let differenceInTime = second.getTime() - first.getTime();
      let differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if (differenceInDays <= 31) {
        const info: GetExportInfoRequest = {
          tag: "data",
          start: dateFormatter(new Date(values.date[0])),
          end: dateFormatter(new Date(values.date[1])),
          entity_id: installation_id,
        };
        const intervalId = setInterval(
          message.warning(
            "Stiamo elaborando il download. Potrebbe richiedere un po' di tempo."
          ),
          30000
        );
        getExportInfo(info)
          .then((resp) => {
            clearInterval(intervalId);
            if (resp && resp.url) {
              downloadFile(resp.url);
            } else if (resp && resp.err) {
              message.error(
                resp.err?.message ?? "Errore nel download del file"
              );
            }
          })
          .catch((e) => console.log("error: ", e));
      } else {
        message.error("Possono essere selezionati massimo 31 giorni.");
      }
    });
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack title={dataHistoryTitle} key="data_history_title" />
      <InstallationSummary selected_installation={selectedInstallation} />
      <div className="my-container my-container-responsive">
        <Form
          className="exportForm"
          layout="vertical"
          key={1}
          form={form}
          name="datahistory_panel"
          requiredMark="optional"
        >
          <Form.Item
            name="date"
            label="Seleziona le date per cui scaricare i dati"
            rules={[{ required: true, message: "Inserire la data!" }]}
          >
            <DatePicker.RangePicker style={{ width: "100%" }} size="large" />
          </Form.Item>
          <div className="btn-container">
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnConfirm={() => form.resetFields()}
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={handleSubmit}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default DataHistoryPanel;
