import React, { useEffect, useState } from "react";
import { Descriptions, Tag } from "antd";
import { GetUserProfile } from "../../../api/services/userService";
import { UserType } from "../../types/profile/types";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { roles } from "../costants";
import SubscriptionManagement from "../subscriptionSManagement/SubscriptionManagement";

type ProfileProps = {};

const Profile: React.FC<ProfileProps> = ({}) => {
  const [profileItem, setProfileItem] = useState<UserType | null>(null);

  const history = useHistory();
  useEffect(() => {
    GetUserProfile().then((res) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        setProfileItem({
          ...profileItem,
          uid: res["user"]["uid"],
          name: res["user"]["name"],
          email: res["user"]["email"],
          role: res["user"]["roles"][0],
          user_info: {
            phone: res["user"]["user_info"]["phone"],
            address: res["user"]["user_info"]["address"],
            city: res["user"]["user_info"]["city"],
            province: res["user"]["user_info"]["province"],
            country: res["user"]["user_info"]["country"],
            zip_code: res["user"]["user_info"]["zip_code"],
          },
          created_at: res["user"]["created_at"],
        });
      } else {
        history.push("/");
      }
    });
  }, []);

  const dateFormatter = (date: string) =>
    moment(date).format("DD/MM/YYYY HH:mm");

  if (profileItem) {
    return (
      <>
        <div className="profileDescriptionContainer">
          <Descriptions
            className="profileDescription profileDescriptionResponsive"
            title="Dati Utente"
            column={2}
            bordered
          >
            <Descriptions.Item label="Nome">
              {profileItem.name}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {profileItem.email}
            </Descriptions.Item>
            <Descriptions.Item label="Ruolo">
              <Tag color="blue"> {profileItem.role} </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Telefono">
              {profileItem.user_info.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Indirizzo">
              {profileItem.user_info.address}
            </Descriptions.Item>
            <Descriptions.Item label="Città">
              {profileItem.user_info.city}
            </Descriptions.Item>
            <Descriptions.Item label="Provincia">
              {profileItem.user_info.province}
            </Descriptions.Item>
            <Descriptions.Item label="Paese">
              {profileItem.user_info.country}
            </Descriptions.Item>
            <Descriptions.Item label="CAP">
              {profileItem.user_info.zip_code}
            </Descriptions.Item>
            <Descriptions.Item label="Data Creazione">
              {dateFormatter(profileItem.created_at)}
            </Descriptions.Item>
          </Descriptions>
        </div>
        {profileItem.role === roles.customer ? (
          <SubscriptionManagement />
        ) : null}
      </>
    );
  } else return null;
};

export default Profile;
