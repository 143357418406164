import "./FormInput.css";
import { Checkbox, Form } from "antd";
import React from "react";

type FormInputProps = {
  placeholder: string;
  type: string;
  keyValue: string;
  name?: string;
  value?: string | number | boolean;
  rules?: any;
  disabled?: boolean;
  onchange?: any;
};

const FormInput: React.FC<FormInputProps> = ({
  placeholder,
  type,
  keyValue,
  name,
  value,
  rules,
  disabled,
  onchange,
}) => {
  if (type === "checkbox") {
    return (
      <div className="form__group field" key={keyValue + "div"}>
        <label key={keyValue + "label"} htmlFor="name" className="form__label">
          {placeholder}
        </label>
        <Form.Item
          key={keyValue + "form"}
          name={name}
          rules={rules ?? undefined}
        >
          <Checkbox
            key={keyValue}
            checked={value === true}
            onChange={onchange}
            type={type}
          >
            {" "}
          </Checkbox>
        </Form.Item>
      </div>
    );
  } else {
    return (
      <div className="form__group field" key={keyValue + "div"}>
        <label key={keyValue + "label"} htmlFor="name" className="form__label">
          {placeholder}
        </label>
        <Form.Item
          key={keyValue + "form"}
          name={name}
          initialValue={value}
          rules={rules ?? undefined}
        >
          <input
            key={keyValue}
            className="form__field"
            placeholder={placeholder}
            type={type}
            disabled={disabled}
          />
        </Form.Item>
      </div>
    );
  }
};

export default FormInput;
