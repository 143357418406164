import { Card } from "antd";
import React from "react";
import Meta from "antd/lib/card/Meta";
import { SettingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { deviceUpdateConnectivity, signalConfigurationUrl } from "../costants";

type CardOptionProps = {
  installationId: string;
};

const CardOption: React.FC<CardOptionProps> = ({ installationId }) => {
  const history = useHistory();

  return (
    <>
      <Card
        hoverable
        bordered
        className="card"
        actions={[
          <p
            className="card-opt"
            onClick={() =>
              history.push(deviceUpdateConnectivity(installationId))
            }
          >
            Modifica connettività
          </p>,
          <p
            className="card-opt"
            onClick={() => history.push(signalConfigurationUrl(installationId))}
          >
            Calibrazione segnali
          </p>,
        ]}
      >
        <Meta
          avatar={<SettingOutlined />}
          title="Opzioni installazione"
          description="Selezionare l'opzione desiderata"
        />
      </Card>
    </>
  );
};

export default CardOption;
