import { message, Popconfirm, Switch } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Subscription } from "../../../api/requests/subscription";
import {
  getAllSubscriptions,
  updateSubscriptionStatus,
} from "../../../api/services/subscriptionService";
import { updateSubscriptionUrl } from "../costants";
import CustomTitle from "../../CustomTitle";
import { useHistory } from "react-router-dom";
import ResponsiveTable from "../../responsiveTable";
import { subscriptionsTableColumns } from "./inputs";
import { updateSubscriptionTitle } from "../title";

type SelectSubscriptionPanelProps = {
  role: string;
};

const SelectSubscription: React.FC<SelectSubscriptionPanelProps> = ({
  role,
}) => {
  document.body.style.background = "#f5f5f5c7";

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  const history = useHistory();

  const getSubscriptions = useCallback(() => {
    getAllSubscriptions("-1", "", "").then((res) => {
      if (res && res.subscriptions) {
        const subscriptionsWithKey = res.subscriptions.map(
          (item: Subscription, index: number) => {
            return { ...item, key: index };
          }
        );
        setSubscriptions(subscriptionsWithKey);
      }
    });
  }, []);

  useEffect(() => {
    getSubscriptions();
  }, []);

  const linkFunction: (value: string) => void = (value: string) =>
    history.push(updateSubscriptionUrl(value));

  const updateState = (record: Subscription) => {
    return (
      <div className="button">
        <Popconfirm
          title="Sei sicuro?"
          onConfirm={() => {
            updateSubscriptionStatus({
              subscription_id: record.id,
              status: record.status === "enabled" ? "disabled" : "enabled",
            }).then((res) => {
              if (res && res.result && !res.error) {
                message.success(res.result, 4);
                getSubscriptions();
              } else {
                message.error(res?.error || "Operazione fallita", 4);
              }
            });
          }}
          onCancel={() => {}}
          okText="Si"
          cancelText="No"
        >
          <Switch size="small" checked={record?.status === "enabled"} />
        </Popconfirm>
      </div>
    );
  };

  return (
    <>
      <CustomTitle title={updateSubscriptionTitle} />
      <ResponsiveTable
        columns={subscriptionsTableColumns(linkFunction, role, updateState)}
        data={subscriptions}
      />
    </>
  );
};

export default SelectSubscription;
