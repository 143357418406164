import { InputType } from "../../types/form/types";

export const select = {
  label: "Seleziona la tipologia di connettività",
  name: "connection_type",
  placeholder: "...",
  options: [
    { label: "WiFi", value: "wifi", disabled: false },
    { label: "GSM", value: "gsm", disabled: false },
  ],
};

export const fw = {
  label: "Seleziona la tipologia di firmware",
  name: "firmware_type",
  placeholder: "...",
  options: [
    { label: "Locthmans", value: "standard", disabled: false },
    {
      label: "Pinza amperometrica",
      value: "pinza_amperometrica",
      disabled: false,
    },
  ],
};

export const wifi = [
  {
    label: "Nome rete",
    name: "sid",
    requiredLabel: "Inserire Nome rete!",
    placeholder: "Nome rete",
    type: "text",
  },
  {
    label: "Password",
    name: "password",
    requiredLabel: "Inserire Password !",
    placeholder: "Password",
    type: "password",
  },
];

export const gsm = {
  label: "Seleziona l'operatore telefonico",
  requiredLabel: "Inserire operatore telefonico!",
  name: "operator",
  placeholder: "...",
  options: [
    { label: "Zerynth Sim", value: "iot.secure" },
    { label: "Vodafone", value: "mobile.vodafone.it" },
    { label: "TIM", value: "ibox.tim.it" },
    { label: "Wind", value: "internet.wind" },
    { label: "Things Mobile", value: "TM" },
  ],
};

export const resultStatus = {
  success: "success",
  error: "error",
};

export const resultTitle = {
  success: "L'operazione è stata completata con successo",
  error: "L'operazione non è andata a buon fine",
};

export const installationFields: InputType[] = [
  {
    key: "spd_en",
    name: "spd_en",
    type: "radio",
    options: [
      { label: "Abilitato", value: "enabled" },
      { label: "Disabilitato", value: "disabled" },
    ],
    label: "Velocità volano",
    rules: [{ required: true, message: "Campo obbligatorio" }],
  },
  {
    key: "drag_en",
    name: "drag_en",
    type: "radio",
    options: [
      { label: "Abilitato", value: "enabled" },
      { label: "Disabilitato", value: "disabled" },
    ],
    label: "Velocità trascinamento",
    rules: [{ required: true, message: "Campo obbligatorio" }],
  },
  {
    key: "ta1_wthresh",
    name: "ta1_wthresh",
    label: "Soglia di lavoro consumo generale (kW)",
    rules: [{ required: true, message: "Campo obbligatorio" }],
  },
  {
    key: "ta2_wthresh",
    name: "ta2_wthresh",
    label: "Soglia di lavoro volano (kW)",
    rules: [{ required: true, message: "Campo obbligatorio" }],
  },
  {
    key: "ta3_wthresh",
    name: "ta3_wthresh",
    label: "Soglia di lavoro motore di trascinamento (kW)",
    rules: [{ required: true, message: "Campo obbligatorio" }],
  },
];

export const standardInstallationFields: InputType[] = [
  {
    key: "drag_en",
    name: "drag_en",
    type: "radio",
    options: [
      { label: "Abilitato", value: "enabled" },
      { label: "Disabilitato", value: "disabled" },
    ],
    label: "Velocità trascinamento",
    rules: [{ required: true, message: "Campo obbligatorio" }],
    colSpan: 12,
  },
  {
    key: "am_en",
    name: "am_en",
    type: "radio",
    options: [
      { label: "Abilitato", value: "enabled" },
      { label: "Disabilitato", value: "disabled" },
    ],
    label: "Automatico manuale",
    rules: [{ required: true, message: "Campo obbligatorio" }],
    colSpan: 12,
  },
  {
    key: "divider_1",
    name: "divider_1",
    type: "divider",
    label: "Velocità volano",
    colSpan: 24,
  },
  {
    key: "spd_en",
    name: "spd_en",
    type: "radio",
    options: [
      { label: "Abilitato", value: "enabled" },
      { label: "Disabilitato", value: "disabled" },
    ],
    label: "Velocità volano",
    rules: [{ required: true, message: "Campo obbligatorio" }],
    colSpan: 8,
  },
  {
    key: "spd_en_min",
    name: "spd_en_min",
    label: "Minimo",
    rules: [{ required: true, message: "Campo obbligatorio" }],
    colSpan: 8,
  },
  {
    key: "spd_en_max",
    name: "spd_en_max",
    label: "Massimo",
    rules: [{ required: true, message: "Campo obbligatorio" }],
    colSpan: 8,
  },
  {
    key: "divider_2",
    name: "divider_2",
    type: "divider",
    label: "Consumo di potenza",
    colSpan: 24,
  },
  {
    key: "ta_multi",
    name: "ta_multi",
    type: "radio",
    options: [
      { label: "Abilitato", value: "enabled" },
      { label: "Disabilitato", value: "disabled" },
    ],
    label: "Macchina trifase",
    rules: [{ required: true, message: "Campo obbligatorio" }],
    colSpan: 12,
  },
  {
    key: "ta_threshold",
    name: "ta_threshold",
    label: "Soglia macchina in lavorazione",
    rules: [{ required: true, message: "Campo obbligatorio" }],
    colSpan: 12,
  },
];
