import { Wire } from "../../types/timeseries/types";
import {
  dateType,
  numberType,
  selectInput,
} from "../../../utilities/utilities";
import {
  mountingOptions,
  wireDiameterOptions,
  wireTypeOptions,
} from "../addwire/inputs";

export const modelOptions = [
  { "Cave di marmo": "Cave di marmo" },
  { "Squadratura marmi": "Squadratura marmi" },
  { "Sagomatura marmi": "Sagomatura marmi" },
  { "Cave di granito": "Cave di granito" },
  { "Squadratura blocchi di granito": "Squadratura blocchi di granito" },
  { "Sagomatura granito": "Sagomatura granito" },
  { Multifilo: "Multifilo" },
  { "Cemento armato e acciaio": "Cemento armato e acciaio" },
  { "Acciaio e strutture metalliche": "Acciaio e strutture metalliche" },
];

export const materialOptions = [
  { Tenero: "Tenero" },
  { Medio: "Medio" },
  { Duro: "Duro" },
  { Marmo: "Marmo" },
  { Granito: "Granito" },
  { "Calcestruzzo armato": "Calcestruzzo armato" },
  { "Metallo/acciao": "Metallo/acciao" },
  { "Acciao inox": "Acciao inox" },
];

export const wireFields = (wire: Wire) => [
  {
    key: "name",
    name: "name",
    label: "Nome",
    rules: [{ required: true, message: "Inserire nome!" }],
    value: wire.name,
  },
  {
    key: "description",
    name: "description",
    label: "Descrizione",
    rules: [{ required: true, message: "Inserire descrizione!" }],
    value: wire.description,
  },
  {
    key: "model",
    name: "model",
    label: "Modello",
    type: selectInput,
    options: modelOptions,
    rules: [{ required: true, message: "Selezionare modello!" }],
    value: wire.model,
  },
  {
    key: "material",
    name: "material",
    label: "Materiale",
    type: selectInput,
    options: materialOptions,
    rules: [{ required: true, message: "Selezionare materiale!" }],
    value: wire.wire_info.material,
  },
  {
    key: "production_date",
    name: "production_date",
    label: "Data Produzione",
    type: dateType,
    rules: [{ required: true, message: "Inserire data di produzione!" }],
    value: wire.wire_info.production_date,
  },
  {
    key: "length",
    name: "length",
    label: "Lunghezza (m)",
    type: numberType,
    value: wire.wire_info.length,
  },
  {
    key: "mount_type",
    name: "mount_type",
    label: "Montaggio",
    type: selectInput,
    options: mountingOptions,
    rules: [{ required: true, message: "Selezionare montaggio!" }],
    value: wire.wire_info.mount_type,
  },
  {
    key: "diameter",
    name: "diameter",
    label: "Diametro (mm)",
    type: numberType,
    //options: diameterOptions,
    rules: [{ required: true, message: "Selezionare diametro!" }],
    value: wire.wire_info.diameter,
  },
  {
    key: "pearl_meter",
    name: "pearl_meter",
    label: "Perle/metro",
    //options: pearlMeterOptions,
    type: numberType,
    rules: [{ required: true, message: "Selezionare perle/metro!" }],
    value: wire.wire_info.pearl_meter,
  },
  {
    key: "wire_type",
    name: "wire_type",
    label: "Tipo fune",
    type: selectInput,
    options: wireTypeOptions,
    rules: [{ required: true, message: "Selezionare tipo fune!" }],
    value: wire.wire_info.wire_type,
  },
  {
    key: "wire_diameter",
    name: "wire_diameter",
    label: "Diametro Fune (mm)",
    type: selectInput,
    options: wireDiameterOptions,
    rules: [{ required: true, message: "Selezionare diametro fune!" }],
    value: wire.wire_info.wire_diameter,
  },
  {
    key: "max_usage",
    name: "max_usage",
    label: "Utilizzo Massimo (h)",
    type: numberType,
    rules: [{ required: true, message: "Inserire utilizzo massimo!" }],
    value: String(Number(wire.wire_info.max_usage) / 3600),
  },
];
