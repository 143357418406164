import React from "react";
import { Result } from "antd";
import TitleWithBack from "../containers/TitleWithBack";

type OperationResultProps = {
  status: "success" | "error";
  operation: "create" | "update";
  entity: "user" | "installation" | "subscription" | "wire" | "signal";
};

const OperationResult: React.FC<OperationResultProps> = ({
  status,
  operation,
  entity,
}) => {
  const messages = {
    create: {
      user: {
        success: "Utente creato con successo",
        error: "Errore nella creazione dell'utente",
      },
      installation: {
        success: "Installazione creata con successo",
        error: "Errore nella creazione dell'installazione",
      },
      subscription: {
        success: "Abbonamento creato con successo",
        error: "Errore nella creazione dell'abbonamento",
      },
      wire: {
        success: "Cavo registrato con successo",
        error: "Errore nella registrazione del cavo",
      },
      signal: {
        success: "Configurazione segnali inviata correttamente",
        error: "Errore durante la configurazione dei segnali",
      },
    },
    update: {
      user: {
        success: "Utente aggiornato con successo",
        error: "Errore durante l'aggiornamento dell'utente",
      },
      installation: {
        success: "Installazione creata con successo",
        error: "Errore durante l'aggiornamento dell'installazione",
      },
      subscription: {
        success: "Abbonamento aggiornato con successo",
        error: "Errore durante l'aggiornamento dell'abbonamento",
      },
      wire: {
        success: "Cavo aggiornato con successo",
        error: "Errore durante l'aggiornamento del cavo",
      },
      signal: {
        success: "Configurazione segnali modificata correttamente",
        error: "Errore durante la modifica della configurazione dei segnali",
      },
    },
  };

  return (
    <>
      <TitleWithBack title={""} toHomePage={true} />
      <Result status={status} title={messages[operation][entity][status]} />
    </>
  );
};

export default OperationResult;
