import { Popconfirm, Button } from "antd";
import React from "react";

type ButtonConfProps = {
  buttonType?: "text" | "link" | "ghost" | "default" | "primary" | "dashed";
  buttonLabel: string;
  buttonOnConfirm: any;
  buttonOnCancel: any;
  questionLabel?: string;
  yesLabel?: string;
  noLabel?: string;
  disabled?: boolean;
};

const ButtonConfItem: React.FC<ButtonConfProps> = ({
  buttonType,
  buttonLabel,
  buttonOnConfirm,
  buttonOnCancel,
  questionLabel,
  yesLabel,
  noLabel,
  disabled,
}) => {
  return (
    <Popconfirm
      title={questionLabel ?? "Sei sicuro?"}
      onConfirm={buttonOnConfirm}
      onCancel={buttonOnCancel}
      okText={yesLabel ?? "Si"}
      cancelText={noLabel ?? "No"}
    >
      <Button
        disabled={disabled ? disabled : undefined}
        size="large"
        type={buttonType ?? "default"}
      >
        {buttonLabel}
      </Button>
    </Popconfirm>
  );
};

export default ButtonConfItem;
