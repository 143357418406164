import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import { Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { GetCustomers } from "../../../api/services/userService";
import { User } from "../../../api/requests/userService";
import { addSubscription } from "../../../api/services/subscriptionService";
import { CreateSubscriptionRequest } from "../../../api/requests/subscription";
import CustomTitle from "../../CustomTitle";
import FormInput from "../../input/FormInput";
import { companyInfo } from "./inputs";
import FormSelect from "../../select/FormSelect";
import { countries } from "../../countries";
import { addSubscriptionTitle } from "../title";
import OperationResult from "../../OperationResult";

const { Option } = Select;

const AddSubscription: React.FC = () => {
  const [customers, setCustomers] = useState<User[]>([]);
  const [status, setStatus] = useState<"success" | "error" | null>(null);

  useEffect(() => {
    GetCustomers("-1", "", "").then((res) => {
      if (res && res.customers) {
        setCustomers(res.customers);
      }
    });
  }, []);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      sm: {
        span: 20,
      },
    },
  };

  const handleSubmit = () => {
    return form.validateFields().then((values) => {
      const req: CreateSubscriptionRequest = {
        customer_id: values.selectCustomer,
        company_info: {
          name: values.name,
          sdi: values.sdi,
          pec: values.pec,
          vat_number: values.vat_number,
          phone: values.phone,
          address: values.address,
          city: values.city,
          province: values.province,
          country: values.country,
          zip_code: values.zip_code,
        },
      };
      addSubscription(req).then((res) => {
        if (res && !res.err) {
          setStatus("success");
        } else {
          setStatus("error");
        }
      });
    });
  };

  if (status) {
    return (
      <OperationResult
        status={status}
        operation={"create"}
        entity={"subscription"}
      />
    );
  }

  return (
    <>
      <CustomTitle title={addSubscriptionTitle} />
      <div className="form-container-sub my-form-container-sub-responsive">
        <Form
          {...formItemLayout}
          key={1}
          form={form}
          name="user_panel"
          requiredMark="optional"
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>Seleziona un utente</p>
          </div>
          <Form.Item
            style={{ display: "flex", justifyContent: "center" }}
            key="itemSelectCustomer"
            name="selectCustomer"
            rules={[{ required: true }]}
          >
            <Select
              key="selectCustomer"
              size="large"
              placeholder="Seleziona utente"
              optionFilterProp="children"
            >
              {customers.map((el, index) => (
                <Option value={el.uid} key={index}>
                  {el.email}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={24} key={"companyInfo"}>
            {companyInfo.map((el, index) => {
              return (
                <Col span={width <= 575 || height <= 815 ? 12 : 8} key={index}>
                  {el.type !== "select" ? (
                    <FormInput
                      key={el.key + "forminput"}
                      keyValue={el.key}
                      name={el.name}
                      rules={el.rules}
                      placeholder={el.label}
                      type={el.type ?? "text"}
                    />
                  ) : (
                    <FormSelect
                      key={el.key + "forminput"}
                      placeholder={el.label}
                      keyValue={el.key}
                      rules={el.rules}
                      name={el.name}
                      options={countries}
                    />
                  )}
                </Col>
              );
            })}
          </Row>
          <div className="btn-container">
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnConfirm={() => form.resetFields()}
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={handleSubmit}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddSubscription;
