import React from "react";
import { Descriptions } from "antd";
import { Subscription } from "../../api/requests/subscription";

type SubscriptionSummaryProps = {
  subscription: Subscription | null;
};

const SubscriptionSummary: React.FC<SubscriptionSummaryProps> = ({
  subscription,
}) => {
  if (subscription) {
    return (
      <div className="summary-description">
        <Descriptions
          labelStyle={{ fontWeight: "bold" }}
          title="Utenza"
          bordered={true}
          size="small"
        >
          <Descriptions.Item label="Nome">
            {subscription.company_info.name}
          </Descriptions.Item>
          <Descriptions.Item label="SDI">
            {subscription.company_info.sdi}
          </Descriptions.Item>
          <Descriptions.Item label="PEC">
            {subscription.company_info.pec}
          </Descriptions.Item>
          <Descriptions.Item label="VAT">
            {subscription.company_info.vat_number}
          </Descriptions.Item>
          <Descriptions.Item label="Telefono">
            {subscription.company_info.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Indirizzo">
            {subscription.company_info.address},{subscription.company_info.city}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  } else {
    return null;
  }
};

export default SubscriptionSummary;
