import "./resetPassword.css";
import ButtonItem from "../../ButtonItem";
import { inputs } from "./inputs";
import { Link, useHistory } from "react-router-dom";
import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { resetPassword } from "../../../api/services/loginService";
import { resetPasswordPageUrlResult } from "../costants";
import logo from "../../../logo/logo-wires02.png";

type ResetPanelProps = {};

const ResetPassword: React.FC<ResetPanelProps> = () => {
  document.body.style.background =
    "radial-gradient(circle, rgba(250,238,12,1) 13%, rgba(245,202,38,1) 32%, rgba(8,159,199,1) 100%)";
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [form] = Form.useForm();

  const history = useHistory();
  const submit = () => {
    form.validateFields().then((values) => {
      resetPassword({
        email: values.email,
      }).then((res) => {
        if (res && !res.err) {
          history.push(resetPasswordPageUrlResult);
        }
      });
    });
  };

  return (
    <>
      <div className={width <= 768 ? "reset-box-responsive" : "reset-box"}>
        <div className="image-container">
          <img src={logo} className="image" alt="logo" />
        </div>
        <Form name="normal_login" form={form}>
          <h2> Reset password </h2>
          {inputs.map((el) => {
            return (
              <Form.Item key={el.key} name={el.name} rules={el.rules}>
                <Input
                  size="large"
                  type={el.type ?? "text"}
                  prefix={el.icon}
                  placeholder={el.label}
                />
              </Form.Item>
            );
          })}
          <Link className="reset-form-forgot" to="/login">
            Torna al login
          </Link>
          <div className="reset-btn-container">
            <Form.Item>
              <ButtonItem
                buttonType="primary"
                label="Reset"
                buttonOnClick={submit}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ResetPassword;
